<script setup lang="ts">
import { ref } from "vue";
import i18n from "@/i18n.js";

import {
  GrModal,
  GrModalContent,
  GrModalHeader,
} from "@/components/GrComponents";

const props = defineProps({
  contractTerm: {
    type: Object,
    default: {},
  },
});

const isModalOpen = ref(false);
const loading = ref(false);
const canClose = ref(true);
const size = ref("md");
</script>

<template>
  <GrModal
    v-model="isModalOpen"
    :close="canClose"
    renderTriggerTo="#TeleporRenderModalContractVersion"
  >
    <template #open>
      <slot></slot>
    </template>

    <GrModalContent
      :size="size"
      customWidth="863"
      customHeight="759"
      :loading="loading"
    >
      <GrModalHeader class="custom-modal-header">
        <div class="custom-header-version-contract-title">
          <h5>{{ i18n.t("views.seller.contracts_new.modal.text_013") }}</h5>
          <p>
            {{ i18n.t("views.seller.contracts_new.modal.text_014") }}
            {{ props.contractTerm.version }}
          </p>
        </div>
      </GrModalHeader>

      <div class="contract-version">
        <v-md-preview :text="props.contractTerm.body" />
      </div>
    </GrModalContent>
  </GrModal>
</template>

<style lang="scss" scoped>
.custom-header-version-contract-title {
  display: flex;
  flex-direction: column;

  h5 {
    position: fixed;
    left: 35px;
    top: 32px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: var(--old-800);
  }

  p {
    position: fixed;
    left: 35px;
    top: 64px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--gray-200);
  }
}

.contract-version {
  border-radius: 10px;
  background: var(--gray-10);
  overflow-y: auto;
  width: 799px;
  height: 615px;
  color: var(--gray-400);
  font-size: var(--font-sm);
}

.custom-modal-header {
  margin-bottom: 23px;
}
</style>
